import { Shop } from '@/api/types/shops.types';
import { PageInfo } from '@/api/types/pageInfo.types';

export enum ServiceName {
  VIP = 'VIP',
  PREMIUM = 'PREMIUM',
  LATEST = 'LATEST',
}

export enum AdOrder {
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
  RANDOM = 'RANDOM',
}

export enum AdFlag {
  BUMPED = 'BUMPED',
  PREMIUM = 'PREMIUM',
  VIPPED = 'VIPPED',
}

export type AssetUrl = {
  url: string;
};

export type Ad = {
  id: string;
  flags: AdFlag[];
  photo: AssetUrl;
  photos: AssetUrl[];
  price?: number;
  region: string;
  path: string;
  shop: Shop | null;
  title: string;
  updatedAt: string;
  legacyResourceId: number;
};

export type GetAdsResponse = {
  ads: {
    nodes: Ad[];
    pageInfo: PageInfo;
  };
};

export type GetAdsForBookmarksResponse = {
  ads: {
    nodes: Ad[];
  };
};
