import React, { useState } from 'react';

export const useToggle = (
  defaultValue?: boolean
): [
  boolean,
  (v?: boolean | React.MouseEvent<HTMLElement>) => void,
  () => void,
  () => void
] => {
  const [isOpen, setIsOpen] = useState(defaultValue ?? false);

  function toggle(v?: boolean | React.MouseEvent<HTMLElement>) {
    setIsOpen(prev => (v && typeof v === 'boolean' ? v : !prev));
  }

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return [isOpen, toggle, open, close];
};
