import styled from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { Button } from '@/components/UI/Button/Button';
import { SkeletonLoader } from '@/components/SkeletonLoader/SkeletonLoader';
import {
  CARD_HEIGHT,
  CARD_HEIGHT_WITH_ACTIONS,
} from '@/components/AdCard/AdCard.constants';
import { ellipsisMultiline } from '@/styles/helpers';

export const SkeletonTitle = styled(SkeletonLoader)`
  margin-top: 10px;
  margin-bottom: 17px;
`;

export const Box = styled.div<{ $hasActions?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $hasActions }) =>
    `${$hasActions ? CARD_HEIGHT_WITH_ACTIONS : CARD_HEIGHT}px`};
  overflow: hidden;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
`;

export const ImageBox = styled.div`
  display: flex;
  border-radius: 7px 7px 0 0;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
  }
`;

export const TextsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex: 1 0 auto;
`;

export const Title = styled(Typography.Title)<{ $hasActions?: boolean }>`
  ${ellipsisMultiline(2)};
  margin-top: 2px;
  margin-bottom: ${({ $hasActions }) => ($hasActions ? '2px' : 'auto')};
  max-height: 2.4em;
  max-width: 80%;
  color: ${({ theme }) => theme.palette.primary.labels};
`;

export const IsFavorite = styled(Button)`
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  padding: 10px 15px;
`;

export const Label = styled(Typography.Caption)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.system.deepBlue};
  color: ${({ theme }) => theme.palette.system.white};
  border-radius: 4px;
  padding: 3px 5px;
`;

export const Services = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.system.white};
`;

export const Action = styled(Typography.Caption)`
  margin-top: auto;
  margin-bottom: 5px;
`;
