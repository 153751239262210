import { format as formatFunction } from 'date-fns';
import i18n, { TFunction } from 'i18next';
import { az, ru } from 'date-fns/locale';
import { DateTimeFormat } from '@/constants/common';

const isSameDay = (date1: Date, date2: Date): boolean =>
  date1.getDate() === date2.getDate() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getFullYear() === date2.getFullYear();

const isYesterday = (date1: Date, date2: Date): boolean => {
  const yesterday = new Date(date1);
  yesterday.setDate(yesterday.getDate() - 1);
  return isSameDay(yesterday, date2);
};

const getDateLabel = (
  withTime: boolean,
  time: string,
  label: string,
  t: TFunction
): string => (withTime ? `${t(label)}, ${time}` : t(label));

export const getMessageTime = (
  dateTime: string | Date,
  t: TFunction,
  withTime = false
): string => {
  const today = new Date();
  const messageDate = new Date(+dateTime);

  const time = formatFunction(new Date(+dateTime), DateTimeFormat.time);

  switch (true) {
    case isSameDay(today, messageDate):
      return getDateLabel(withTime, time, 'common.today', t);
    case isYesterday(today, messageDate):
      return getDateLabel(withTime, time, 'common.yesterday', t);
    default:
      return formatFunction(+dateTime, DateTimeFormat.longMonthWithYear, {
        locale: i18n.language === 'az' ? az : ru,
      });
  }
};
