import { PAGE_WIDTH } from '@/components/AdCards/AdCards.constants';

export function getCardsCountInRow(pageWidth: number) {
  switch (true) {
    case pageWidth >= PAGE_WIDTH.XL:
      return 6;
    case pageWidth >= PAGE_WIDTH.LG:
      return 5;
    case pageWidth >= PAGE_WIDTH.MD:
      return 4;
    case pageWidth >= PAGE_WIDTH.SM:
      return 3;

    default:
      return 2;
  }
}
