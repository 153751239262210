import React, { useEffect, useState } from 'react';
import * as S from '@/components/AdCard/AdCard.styles';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { useTranslation } from 'react-i18next';
import { HeartActiveIcon, HeartIcon } from '@/components/UI/Icons/Icons';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import { formatNumber } from '@/utils/helpers';
import { SkeletonLoader } from '@/components/SkeletonLoader/SkeletonLoader';
import { Ad, AdFlag } from '@/api/types/ads.types';
import { getMessageTime } from '@/utils/datetime.utils';
import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';
import NoSsr from '@/components/NoSsr/NoSsr';
import { useSaveScrollPositionActions } from '@/components/ScrollSaver/ScrollSaver.hooks';
import { CustomLink } from '@/components/CustomLink/CustomLink';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';

interface AdCardProps {
  ad?: Ad;
  isLoading?: boolean;
  className?: string;
  hasActions?: boolean;
  hasFavorite?: boolean;
  onTriggerRemove?: (id: Ad['id']) => void;
  bookmarkIds?: Set<string>;
  toggleBookmark?: (ad: Ad) => Promise<void>;
}

export const AdCard: React.FC<AdCardProps> = ({
  ad,
  isLoading,
  className,
  hasActions = false,
  hasFavorite = true,
  onTriggerRemove,
  bookmarkIds,
  toggleBookmark,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const customPush = useCustomPush();
  const saveScrollPositionActions = useSaveScrollPositionActions();
  const [isFavorite, setIsFavorite] = useState(false);

  async function toggleFavorite(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (!ad) return;

    setIsFavorite(prev => !prev);

    try {
      await toggleBookmark?.(ad);
    } catch (error) {
      setIsFavorite(prev => !prev);
    }
  }

  function onTriggerRemoveClick(e: React.MouseEvent, id: Ad['id']) {
    e.preventDefault();
    onTriggerRemove?.(id);
  }

  function onEditClick(e: React.MouseEvent) {
    e.preventDefault();
    if (!ad) return;

    customPush(`${ad.path}/ad_edits`);
  }

  useEffect(() => {
    if (!ad) return;

    setIsFavorite(!!bookmarkIds?.has(ad.id));
  }, [bookmarkIds?.size]);

  if (!ad || isLoading) {
    return (
      <S.Box
        $hasActions={hasActions}
        className={className}
        data-testid="ad-card-skeleton-loader"
      >
        <SkeletonLoader borderRadius={0} width="100%" height="130px" />
        <S.TextsBox>
          <SkeletonLoader width="74px" />
          <S.SkeletonTitle />
          <SkeletonLoader width="113px" />
        </S.TextsBox>
      </S.Box>
    );
  }

  const isPremium = ad.flags.includes(AdFlag.PREMIUM);
  const isVip = ad.flags.includes(AdFlag.VIPPED);

  const isBookmarksPage = router.pathname === ROUTES.bookmarks;

  return (
    <S.Box
      dataTestId="ad-card"
      data-stat={`ad-${isBookmarksPage ? 'bookmark-' : ''}card-link`}
      as={CustomLink}
      href={ad.path}
      className={className}
      $hasActions={hasActions}
      onClick={() => saveScrollPositionActions()}
    >
      <S.ImageBox>
        <img
          data-testid="ad-card-image"
          src={ad.photo?.url}
          alt={ad.title}
          width={380}
          height={130}
        />
        {hasFavorite && (
          <S.IsFavorite
            data-testid="bookmark-button"
            appearance="transparent"
            onClick={toggleFavorite}
          >
            {isFavorite ? (
              <HeartActiveIcon data-testid="is-bookmarked" />
            ) : (
              <HeartIcon data-testid="is-not-bookmarked" />
            )}
          </S.IsFavorite>
        )}

        {ad.shop && <S.Label>{t('common.shop')}</S.Label>}

        <S.Services>
          {isPremium && (
            <IconBox $filename="crown.svg" $iconWidth={16} $iconHeight={13} />
          )}
          {isVip && (
            <IconBox $filename="vip.svg" $iconWidth={16} $iconHeight={13} />
          )}
        </S.Services>
      </S.ImageBox>
      <S.TextsBox>
        {!!ad.price && (
          <Typography.Numbers $variant="2" data-testid="ad-card-price">
            {formatNumber(ad.price)} AZN
          </Typography.Numbers>
        )}

        <S.Title
          $variant="2"
          $hasActions={hasActions}
          data-testid="ad-card-title"
        >
          {ad.title}
        </S.Title>

        <Typography.Caption data-testid="ad-card-additional" $isEllipsis>
          {ad.region},{' '}
          <NoSsr>{getMessageTime(new Date(ad.updatedAt), t, true)}</NoSsr>
        </Typography.Caption>

        {hasActions && (
          <>
            <S.Action $isLink onClick={onEditClick}>
              {t('common.edit')}
            </S.Action>
            <Typography.Caption
              $isLink
              onClick={e => onTriggerRemoveClick(e, ad.id)}
            >
              {t('common.remove')}
            </Typography.Caption>
          </>
        )}
      </S.TextsBox>
    </S.Box>
  );
};
