import React from 'react';
import { Overlay } from '@/components/UI/Overlay/Overlay.styles';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import * as S from '@/components/UI/Popup/Popup.styles';
import { PopupProps } from '@/components/UI/Popup/Popup.types';
import { useBodyOverflow } from '@/hooks/useBodyOverflow';
import { Portal } from '@/components/UI/Portal/Portal';

export const Popup: React.FC<PopupProps> = ({
  isOpen,
  close,
  icon,
  title,
  description,
  actions,
  isInPortal,
  dataTestId,
}) => {
  useBodyOverflow(isOpen);

  function renderPopup() {
    return (
      <>
        <Overlay $isOpen={isOpen} onClick={close} />
        <S.PopupBox $isOpen={isOpen} data-testid={dataTestId}>
          {!!icon && (
            <IconBox
              className="popup__icon"
              $filename={icon}
              width={25}
              height={25}
              $iconWidth={25}
              $iconHeight={25}
            />
          )}
          {!!title && <S.PopupTitle $variant="3">{title}</S.PopupTitle>}

          {description &&
            (typeof description === 'string' ? (
              <S.PopupDescription $variant="3">
                {description}
              </S.PopupDescription>
            ) : (
              <S.PopupDescriptionBox>{description}</S.PopupDescriptionBox>
            ))}

          <S.PopupActions>
            {actions?.left && (
              <S.PopupAction
                $hasPadding
                $appearance="transparent"
                $isRed={actions?.left?.isRed}
                $isGray={actions?.left?.isGray}
                onClick={actions?.left?.onClick}
                data-testid="popup-action-left"
              >
                {actions?.left.text}
              </S.PopupAction>
            )}
            {actions?.right && (
              <S.PopupAction
                $hasPadding
                $appearance="transparent"
                $isRed={actions?.right?.isRed}
                $isGray={actions?.right?.isGray}
                onClick={actions?.right?.onClick}
                data-testid="popup-action-right"
              >
                {actions?.right.text}
              </S.PopupAction>
            )}
          </S.PopupActions>
        </S.PopupBox>
      </>
    );
  }

  if (isInPortal) {
    return <Portal>{renderPopup()}</Portal>;
  }

  return renderPopup();
};
