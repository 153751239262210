import React, { Fragment, useState } from 'react';
import { AdCard } from '@/components/AdCard/AdCard';
import * as S from '@/components/AdCards/AdCards.styles';
import { Ad } from '@/api/types/ads.types';
import { getDataStatAttr } from '@/utils/helpers';
import { Popup } from '@/components/UI/Popup/Popup';
import { useToggle } from '@/hooks/useToggle';
import { useTranslation } from 'react-i18next';
import { useUserBookmarks } from '@/hooks/useUserBookmarks';
import { AdManagerBanner } from '@/components/Banners/AdManagerBanner';
import { BannerName } from '@/components/Banners/Banners.types';
import { useBookmarksStore } from '@/store/useBookmarksStore/useBookmarksStore';
import { useWindowSize } from 'usehooks-ts';
import { getCardsCountInRow } from '@/components/AdCards/AdCards.helpers';
import { ROWS_COUNT_BEFORE_BANNER } from '@/components/AdCards/AdCards.constants';
import { useAdManagerBannerVisible } from '@/components/Banners/AdManagerBanner.hooks';

interface AdCardsProps {
  ads?: Ad[];
  listDataStat?: string;
  hasActions?: boolean;
  hasFavorite?: boolean;
  hasAdManagerCard?: boolean;
  bannerInList?: (arCn: number) => React.ReactNode;
  isInitialLoading?: boolean;
  isLoadingMore?: boolean;
}

export const AdCards: React.FC<AdCardsProps> = ({
  ads,
  listDataStat,
  hasActions,
  hasFavorite,
  hasAdManagerCard,
  isInitialLoading,
  isLoadingMore,
  bannerInList,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [isPopupOpen, popupToggle] = useToggle();
  const [selectedAdId, setSelectedAdId] = useState('');
  const { toggleBookmark } = useUserBookmarks(false);
  const isAdManagerBannerVisible = useAdManagerBannerVisible();
  const bookmarkIds = useBookmarksStore(state => state.bookmarkIds);

  function onTriggerRemove(id: Ad['id']) {
    setSelectedAdId(id);
    popupToggle();
  }

  function remove() {
    console.log('Ad to remove:', selectedAdId);
    setSelectedAdId('');
    popupToggle();
  }

  function isBannerRenders(idx: number) {
    return (
      idx !== 0 &&
      idx % (ROWS_COUNT_BEFORE_BANNER * getCardsCountInRow(width)) === 0 &&
      bannerInList
    );
  }

  if (isInitialLoading) {
    return (
      <S.Box>
        <AdCard isLoading />
        <AdCard isLoading />
        <AdCard isLoading />
        <AdCard isLoading />
      </S.Box>
    );
  }

  return (
    <>
      <S.Box {...getDataStatAttr({ dataStat: listDataStat })}>
        {ads?.map((ad, idx) => {
          if (hasAdManagerCard && isAdManagerBannerVisible && idx === 3) {
            return <AdManagerBanner key={ad.id} bannerName={BannerName.Card} />;
          }

          if (isBannerRenders(idx)) {
            return (
              <Fragment key={ad.id}>
                <S.BannerBox>{bannerInList?.(idx)}</S.BannerBox>
                <AdCard
                  ad={ad}
                  hasActions={hasActions}
                  hasFavorite={hasFavorite}
                  onTriggerRemove={onTriggerRemove}
                  bookmarkIds={bookmarkIds}
                  toggleBookmark={toggleBookmark}
                />
              </Fragment>
            );
          }
          return (
            <AdCard
              key={ad.id}
              ad={ad}
              hasActions={hasActions}
              hasFavorite={hasFavorite}
              onTriggerRemove={onTriggerRemove}
              bookmarkIds={bookmarkIds}
              toggleBookmark={toggleBookmark}
            />
          );
        })}

        {isLoadingMore && <AdCard isLoading />}
      </S.Box>

      <Popup
        isOpen={isPopupOpen}
        close={popupToggle}
        title={t('pages.cabinet.remove_ad_question')}
        description={t('pages.cabinet.remove_ad_popup_text')}
        isInPortal
        actions={{
          left: { text: t('common.decline'), onClick: popupToggle },
          right: { text: t('common.remove'), isRed: true, onClick: remove },
        }}
      />
    </>
  );
};
